/* App.css */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Noto Serif', serif;
  font-size: 15px;
  background-color: black;
}
.ailogo {
  width:100px;
}
.cr2 {
  width:15px;
}
.crlogo {
  height: 50px;
  width:50px;
  margin:20px;
  text-align: center;
}
.manifest {
  display: block;
  text-align: center;
}
.clog {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}
.song-description {
  text-align: center;

}
.custom-audio::-webkit-media-controls {
}


.error {
  color: red;
  text-align: center;
}
hr {
color: black;
  padding:0;
  margin:0;
}
ol {
  text-align: left;
}
ol li {
  padding-bottom:10px;
  margin-right:40px;
}
.symbol {
  float:left;
  width:100px;
  margin-right:20px;
  margin-left:10px;
}
.breadcrumbs {
  text-align: left;
  padding: 0;
}
.breadcrumbs li {
  list-style: none;
  display:inline-flex;
  margin-top:20px;
  padding:0;

}
.breadcrumbs ul
{  padding-inline-start: 20px;
}
.author {
  font-size: 14px;
  text-align: left;
  display:block;
  margin-left:20px;

}
.navbar {

  position: fixed; /* Fixed positioning */
  top: 0;         /* Anchor it to the top */
  left: 0;        /* Anchor it to the left */
  width: 100%;    /* Make it full width */
  z-index: 1000;  /* Ensure it's on top of other content */
}
article {
  font-size:0.8em;
}
caption {
  display:block;
  font-size:1em;
}
p  {
  margin-left:20px;
  margin-right:20px;
}

.home-text {
  margin: 0 auto;
  margin-bottom:50px;
  font-size: 1.5em;
  margin-left:25px;
  margin-right:25px;
}
.content {
}
.content p {
  display:block;
  text-align: justify;
  font-size:1.25em;

}
p.subscribe {
text-align: center;
}

.content h1, h2 {
  text-align:center;
}
.content h1 {
  font-size: 2em;
}
.contentauth h1 {
  font-size: 2em;
  text-align:center;
}
.contentauth {
  text-align:center;
  font-size:15px;
}
.content ul, div {
  background-color: white;
}

.App {
  text-align: center;
}
.socialicon {
  display:inline-flex;
}
.hero img {
  max-width:500px;
  margin-top:50px;
  float:right;
}
.herosmall {
  width: 200px;
  float:left;
  margin-right:20px;

}
.herosmall-right {
  width: 200px;
  float:right;
  margin-left:20px;

}
.book-images {
  max-width:500px;
  height:auto;
}
.parallax {
  background: url("./img/Lord_Toph-Pieter_Henket-cropped.png");
  min-height: 100px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.musicimage {
  max-width: 500px;
  height:auto;
  display:inline-block;
  margin-top:20px;
}
.blog-summary {
  height: 200px;
  padding:0 0 0 20px;
  background-color: white;
  border-top: white;
  font-size:.8em;

}
.custom-audio::-webkit-media-controls-enclosure {
  display: flex;
  justify-content: space-between;
}

/* App.css */
/* Ensure the existing styles remain, and add specific styles for the tooltip overlay if needed */
.tooltip-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Dark transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.tooltip {
  position: relative;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
}
.tooltip-iframe {
  width: 600px; /* Adjust width as needed */
  height: 400px; /* Adjust height as needed */
  border: none;
}
.tooltip-trigger {
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}
.tooltip-trigger:hover {
  transform: scale(1.2); /* Scale up the text on hover */
}

.psychedelic {
    font-size: 1.5em;
    font-weight: bold;
    background: linear-gradient(to right, #bf00ff, orange, yellow, azure, aqua, yellow, deeppink);
    -webkit-background-clip: text;
    color: transparent;
  animation: fadeIn 2s forwards;
  transition: transform 0.5s, filter 0.5s;
  align-content: center;
 outline: white;
}
.green {
  color:green;
}
.pink {
  color:deeppink;
}
.blue {
  color:#48cdfe;
}
.orange {
  color: darkorange;
}
.contact p {
  text-align: center;
}
.rainbow-text {
  font-size: 3em;
  font-weight: bold;
  background: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet);
  -webkit-background-clip: text;
  color: transparent;
  opacity: 0;
  animation: fadeIn 2s forwards;
  transition: transform 0.5s, filter 0.5s;
}

.psychedelic:hover {
  transform: scale(1.1);
  filter: brightness(1.2);
}


.custom-audio::-webkit-media-controls-timeline,
.custom-audio::-webkit-media-controls-play-button,
.custom-audio::-webkit-media-controls-volume-slider {
  display: block !important;
}
.audiocontrols {
  display:inline-flex;
}
.claimedat {
  text-align:center;
  font-size:15px;
  padding-bottom:20px;
}
button {
  margin: 5px;
}
.buttons-container {
  display:none;
}
.artgallery img {
  max-width:800px;
}
.art-description {
padding-bottom:10px;
}
.clickable-background {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Ensure it's behind any other content */
}
.blog-summary h2 {
  padding-top: 2px;
}
.filebaby {
  background-color: none;
}
.logo {
  /* float:right */
  width:450px;
  padding:40px 10px 13px 0px;
}
.pagelogo {
  /* float:right */
  width:225px;
  margin-top:50px;
}

 .nav-item {
  font-size: 14px;
  list-style: none;
  padding:0;
}

.insetImageLeft {
  display: block; /* Display block to make sure it's not inline */
  width: 250px; /* Set the width of the image */
  float: left; /* Float to the left */
  margin-right: 20px; /* Add some space between the image and the text */
}

/* Float the image to the right */
.insetImageRight {
  display: block; /* Display block to make sure it's not inline */
  width: 250px; /* Set the width of the image */
  float: right; /* Float to the right */
  margin-left: 20px; /* Add some space between the image and the text */
}
.imageContainer {
  text-align: center; /* Centers the content (the image) inside the container */
  padding:0;
}
/* Style for the dropdown button */
.content {
  margin-top:190px;
}

.dropbtn {
  color: white;
  padding: 0 0 0 0;
  font-size: 14px;
  border: none;
  cursor: pointer;
  text-decoration: none; /* Remove underline from link */
  display: inline-block; /* Allow pseudo-elements */
  position: relative; /* Establish positioning context */
  background-color: transparent;

}

/* Add a small arrow after the dropdown link */
.dropbtn::after {
  display:none;
  content: ' ▼';
  font-size: 0.8rem;
  margin-left: 0.5rem;
  color: #f7d347;
  padding-right: 5px;
  position: absolute; /* Position the arrow relative to the dropbtn */
  right: -20px; /* Adjust as needed */
  top: 50%;
  transform: translateY(-50%); /* Center vertically */
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

.contact p {
  text-align: center;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: black;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: darkblue;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
}
span.readytoinspect {
display:none;
}
/* Style for the image */
.centeredImage {
  display: block; /* Treats the image as a block-level element */
  margin: 0 auto; /* Sets the top and bottom margins to 0 and left and right margins to auto, centering the image */
  width: 100%; /* Optional: sets the width of the image */
}
.centeredImageFull {
  display: block; /* Treats the image as a block-level element */
  margin: 0 auto; /* Sets the top and bottom margins to 0 and left and right margins to auto, centering the image */
  width: 500px; /* Optional: sets the width of the image */
}
.leftImage {
  display:block;
  margin-right: 10px;
  margin-top:10px;
  margin-bottom:10px;
  float:left;
}
.Buynow {width:85px;}
.rightImage {
  display:block;
  margin-left: 10px;
  margin-top:10px;
  margin-bottom:10px;
  float:right;
  width:200px;
}
.blogpost {
  margin-top: 250px;
}
.blog-summary h2 {
  text-align:left;
}
.hero {
  background-color: grey; /* Soft blue background for hero section */
}
.hero-img {
  width:100%;
}
.hero-img img {
  transition: opacity 2s ease-in-out;

}
.hero-img-blog {
  width:25%;
}
.top-heading {
  float:left;
}
/*this is used in inspect gif*/
.services {
  padding: 25px 0;
  text-align: center;
  background-color: white;
  color: #000;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);

}
.serviceslogo  {
  width: 100px;
  margin-top:50px;
}
.services h2 {
  color: #2b2d30;
}

.privacy-policy {
  padding: 50px 20px;
  background-color: #61dafb;
  text-align: left;
  font-size: 0.5em;
}

.privacy-policy h2, .terms-of-use h2 {
  color: #2b2d30; /* Or the primary color of your theme */
}
.terms-of-use {
  padding: 0 20px;
  background-color: #61dafb;
  text-align: left;
  font-size: 0.5em;
}
/* Add to App.css */

.content a, a:visited {
  text-decoration:underline;
  color: black;
}

.video iframe {
  width: 100%;
  max-width: 560px; /* Maximum width of the video */
  height: auto; /* Adjust height automatically */
  aspect-ratio: 16 / 9; /* Maintain aspect ratio of 16:9 */
  margin-top: 60px;
}

/* Styles for when menu is active */
.active {
  transform: translateX(0);
}
.homepage-text {
  display: flex;
  margin-bottom: 5px;
}
.homepage-text a {
  display: contents;
  color:white;
  font-style: italic;
}
.artimage {
  padding-top:10px;
}
.data-rmiz-content img {
  visibility: visible !important;
  display: block !important;
}

.literatureimage {
  padding-top: 10px;
  max-height: 300px;
  width: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.thumbnail-navigation img {
  cursor: pointer;
  width: 50px;
  height: 50px;
  margin: 5px;
}

.thumbnail-navigation .active {
  border: 2px solid black;
}
[data-rmiz-ghost] {
  display: none !important;
}
/* Custom class for black background overlay */

/* Custom class for black background overlay */
.black-overlay {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

/* Ensure overlay transitions to black smoothly */
.data-rmiz-overlay {
  transition: background-color 0.3s ease !important;
}

.art {
  font-size:1.5em;
  padding:100px;
  background-color: #d6d6d3;
  font-weight:normal;
  background-image: url("./img/Confusion_number_5.JPG");
  background-repeat:no-repeat;
  background-size: cover;
  background-blend-mode: overlay;
  margin-bottom:0;
  cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
}
/*.art2 {
  font-size:1.5em;
  padding:100px;
  background-color: #d6d6d3;
  font-weight:bold;
  background-image: url("./img/Confusion_number_5.JPG");
  background-repeat:no-repeat;
  background-size: cover;
  margin-bottom:0;
}*/
.blog-summary span {
  height: 300px;
  }
.art a {
  color: blue;
  text-decoration: underline;
}
.music {
  font-size:1.5em;
  padding:200px;
  background-repeat:no-repeat;
  background-size: cover;
  background-image: url("./img/Lord_Toph_music_1.png");
  color:white;
  font-weight:normal;
  margin-bottom:0;
  cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
  background-color: rgba(0, 0, 0, 0.5); /* Default background color */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
  z-index: 2;

}

.music2 {
  font-size:1.5em;
  padding:200px;
  background-repeat:no-repeat;
  background-size: cover;
  background-image: url("./img/Lord_Toph_music_1.png");
  color:white;
  font-weight:bold;
  margin-bottom:0;

}
.books {
  font-size:1.5em;
  padding:200px;
  background-repeat:no-repeat;
  background-size: cover;
  background-image:
          linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./img/MC_Literature_Book_Background.png");
  font-weight:normal;
  margin-bottom:0;
  color:white;
  cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
}
.books a:hover {
display:none;
}
.books2 {
  font-size:1.5em;
  padding:200px;
  background-repeat:no-repeat;
  background-size: cover;
  background-image:
           url("./img/MC_Literature_Book_Background.png");
  font-weight:bold;
  margin-bottom:0;
  color:white;

}
.books2 img {

}
.books p {
  text-align: center;
}
.blog-image  {
  max-width:350px;
}

.footer {
  background-color: transparent;
  background-image: url("./img/Lace_for_lordtoph.com_-_25.png");
  background-size: cover;
  color:white;
  margin:0;
  padding:0;
}
.footer li {
background: transparent;
  margin-right:2px;
}
.footer a {
  background-color: transparent;
  transition: transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
  transition: transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;


}
.media-player img {
  display: block;
  margin: auto;
}

.thumbnail-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.thumbnail {
  cursor: pointer;
  width: 50px;
  height: 50px;
  margin: 5px;
}

.thumbnail.active {
  border: 2px solid black;
}
.literature p {
  margin:0;
  padding: 0;
}
.literature .item,
.discography .item,
.relatedacts .item,
.biography .item {
  text-align: center;
  margin-bottom: 0.5em; /* Adjust the spacing between items if needed */
}

.literature h2, .discography h2, .relatedacts h2, .biography h2,
.literature h3, .discography h3, .relatedacts h3, .biography h3 {
  margin-top: 1em;
  margin-bottom: 0.5em;
}

hr {
  margin-bottom: 1em;
}
.discography {
  margin-bottom:50px;
}
.footer a img {
  background-color: none;
  width:30px;
  margin:5px;
}
.footer a:hover {
  background-color: transparent;
  transform: scale(1.3); /* Scale up the text on hover */
}


.copyright {
  text-align: center;
  color: white;
  background-image: url("./img/Lace_for_lordtoph.com_-_25.png");
  background-size: cover;
  display:block;
  margin:0;
  padding-bottom:70px;
}
.footer img {
  width:32px;
  padding-right:5px;
}
.footer li {
  background-color: transparent;
  text-align:center;
  list-style:none;
  display:inline-flex;
  padding:0;
  margin:0;
 }
.sitelinks {
  display:block;
  list-style: none;
  justify-content: center;
  background: transparent;

}
.sitelinks a {
  color: white;
}
.sociallinks ol {
  text-align:center;
}
.links {
  text-align: center;
  width:100%;
  background: transparent;
}
.links a {
  color: white;
  padding-bottom: 20px;
  padding-top: 20px;

}

.sociallinks a {
  color: black;


}
.sociallinks li {
  list-style:none;
  padding:0;
  margin:0;
  float:left;
  text-align: center;

}
.data-rmiz-content img {
  visibility: visible !important;
  display: block !important;
}

.artimage {
  padding-top: 10px;
  max-height: 300px;
  width: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.thumbnail-navigation img {
  cursor: pointer;
  width: 50px;
  height: 50px;
  margin: 5px;
}

.thumbnail-navigation .active {
  border: 2px solid black;
}

/* Custom class for black background overlay */
.black-overlay {
  background-color: rgba(0, 0, 0, 0.9) !important;
}
.data-rmiz-content img {
  visibility: visible !important;
  display: block !important;
}

.musicimage {
  padding-top: 10px;
  max-height: 300px;
  width: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.thumbnail-navigation img {
  cursor: pointer;
  width: 50px;
  height: 50px;
  margin: 5px;
}

.thumbnail-navigation .active {
  border: 2px solid black;
}

/* Custom class for black background overlay */
.black-overlay {
  background-color: rgba(0, 0, 0, 0.9) !important;
}
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
@media (min-width: 769px) {
  /* Hide the hamburger menu icon */
  .menu-icon {
    display: none;
  }

  /* Style the navigation list for larger screens */
  .nav-list {
    display: flex;
    justify-content: center; /* Center the nav items horizontally */
    align-items: center; /* Align the nav items vertically */
    list-style: none; /* Remove list bullets */
    padding-left: 0; /* Remove padding */
    background-color: black; /* Your desired background color */
    width: 100%; /* Full width of the menu */
    margin-top:0;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    background-image: url("./img/Lace_for_lordtoph.com_-_25.png");
    background-size: cover;
  }
.blog {
  background: white;
}
.tandrlist {
  text-align:center;
}
hr {
  color:grey;
  padding-bottom:5px;
  padding-top: 10px;
}

.tandr-summary  {
font-size:20px;
  padding-bottom:50px;
}
  /* Style each navigation item */
  .nav-item {
    padding: 18px 10px; /* Add padding to each nav item for spacing */
    text-align:left;
  }

  /* Style each navigation link */
  .nav-item a {
    text-decoration: none; /* Remove underline from links */
    color: white; /* Set text color for the links */
  }

  /* Additional styles for the 'X' close icon, if needed */
  .menu-icon span {
    font-size: 2rem; /* Set a large font size for the 'X' icon */
    cursor: pointer; /* Change mouse to pointer when hovering over the 'X' */
  }

}
@media (max-width: 768px) {
  .menu {
    display: none; /* Hide by default */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*background-color: rgba(0, 0, 0, 0.9); /* Black overlay */
    background-color: white;
    /*background-image: url('./img/AdobeStock_124036778.jpg'); /* Add your image path here */
    background-size: cover; /* Cover the entire menu area */
    background-position: center; /* Center the background image */

    z-index: 99;
    justify-content: center;
    align-items: center;
  }
  .logo2 {
    max-width:150px;
  }
  .manifest {
    font-size:9px;
  }
  .menu.active {
    display: flex; /* Show the full-screen menu */
    flex-direction: column;
  }

  .nav-list {
    display: none; /* Hide by default */
    list-style-type: none;
    width: 100%; /* Full width */
    height: 100%; /* Full height, if you want the menu to cover the entire screen */
    padding-top: 1.5rem; /* Adjust if needed */
    box-sizing: border-box;
    flex-flow: column nowrap;
  }

  .nav-list.active {
    display: flex; /* Show on active */
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
  }

  .nav-item {
    color: black; /* Light text for visibility */
    padding: 1rem; /* Spacing around items */
    text-align: left;
    width: 100%; /* Full width */
    font-size: 1em;
  }
  .nav-item a {
    text-decoration: none;
    color: black; /* Light text for visibility */
    display: inline-block; /* Make the link fill the list item for easier touch */
  }

  .menu-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    z-index: 100; /* Above the overlay */
    margin-right: 5px;
  }

  .menu-icon div, .menu-icon span {
    width: 2rem; /* Size of the icon */
    height: 0.15rem; /* Height of the icon lines */
    background-color: black; /* Color of the icon lines */
    border-radius: 5px;
    transition: all 0.3s linear;
  }
  .menu-icon div {
    margin-bottom: 0.5rem;
  }

  .menu-icon span {
    height: auto; /* Auto height for the 'X' character */
    font-size: 2rem; /* Size of the 'X' */
    line-height: 2rem; /* Align 'X' with the icon lines */
    text-align:center;
    color:white;
  }


  /* Add a small arrow after the dropdown link */
  .dropbtn {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    font-family: inherit; /* Match the font of other links */
    display: flex;
    align-items: center;
    justify-content: space-between;
    color:black;
  }

  /* Style for the icons */
  .fa-caret-up, .fa-caret-down {
    margin-left: 0.5rem;
  }
  /* Add a small arrow after the dropdown link */
  .dropbtn::after {
    display: none;
    content: ' ▼';
    font-size: 0.8rem;
    margin-left: 0.5rem;
    color: #f7d347;
    padding-right: 5px;
    position: relative; /* Position the arrow relative to the dropbtn */
    right: -250px; /* Adjust as needed */
    top: 50%;
  }

  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    margin: 0 0 0 0;
  }

  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    float:right;
    margin-left:2rem;
    margin-right:2rem;
    position: relative;
  }

  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {
    background-color: #f1f1f1;

  }
  .dropdown-content {
    display: none;
    background-color: white;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  .dropbtn {
  display:block;
    font-size:1em;
    font-weight:normal;
  }

  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }

  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {
  }

  .ailogo {
    width:100px;
  }
  .home-text {
    font-size: 1em;
    margin-left:20px;
    margin-right:20px;

  }

  .items ul {
    display: none;
  }
  .homepage-text {
    display: inline-block;
  }
  .content p {
    display:block;
    text-align: left;
  }
 .subscribe {
    text-align: center;
  }
  .content {
    margin-top:50px;
  }
  img.caifb {
    margin-top:50px;
    width:100%;
  }
  .art, .music, .books, art2, music2, books2 {
    font-size:1em;
    padding:50px;
    margin-bottom:0;

  }

  .books p {
    text-align: center;
  }
  .logo {
    max-width: 150px;
  }
.caifb {
    padding:0;
    margin:0;
   display:block;
  }

  .odd {
    font-size:1em;
    padding:50px;
  }
  .leftImage {
    display:block;
    margin-right: 10px;
    margin-top:10px;
    margin-bottom:10px;
    width:100%;
    }
  .book-images {
    max-width:250px;
    height:auto;
  }
  .artimage  {
    height:500px;
  }
  .artlogo {
    width:250px;
  }
  @media (max-width: 399px) {
    .video iframe {
      width: 300px;
      height: auto; /* Adjust height automatically */
      aspect-ratio: 16 / 9; /* Maintain aspect ratio of 16:9 */
      margin-top: 60px;
    }
    .artimage {
    height:300px;
    }
    .musicimage {
      max-width: 250px;
      height:auto;
      display:inline-block;
    }
    .centeredImageFull {
      max-width:180px;
    }

    h1 {
      font-size: 1.1em;
      text-align:left;
    }
    .blog-summary {
    height:auto;
    }
    .blog-summary h2 a {
      padding-top: 2px;
      align-content: left;
    }
  }
}
