.audio-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.audio-button {
    background-color: #333;
    color: #fff;
    border: none;
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.audio-button:hover {
    background-color: #555;
}

.audio-button:active {
    background-color: #777;
}

.audio-button:focus {
    outline: none;
}
