.chat-container {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1000;
    margin: 10px;
    background-color: #fff;
    border: 1px solid #48cdfe;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    width: 200px; /* Default width for mobile */
    max-width: 200px; /* Default max width for mobile */
    max-height: 600px; /* Set max height */
    display: flex;
    flex-direction: column;
    transition: width 0.3s ease; /* Smooth transition */
    overflow: hidden; /* Prevent overflow issues */
}

.chat-toggle-button {
    width: 100%;
    padding: 8px; /* Adjusted padding */
    margin: 0; /* Ensure no margin overflow */
    font-weight: bold;
    background-color: #48cdfe;
    color: white;
    cursor: pointer;
    text-align: center;
    border: none; /* Remove border to fit within container */
    border-radius: 8px 8px 0 0; /* Match container's border radius */
    box-sizing: border-box; /* Ensure padding and border are included in the width */
}

.chat-popup {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 10px; /* Add padding inside the chat popup */
    position: relative;
    background-color: #fff;
    overflow: hidden; /* Hide overflow to prevent unwanted scrollbars */
    border-radius: 0 0 8px 8px;
    width: 100%; /* Ensure it takes the width of the container */
    box-sizing: border-box; /* Ensure padding is included in the width calculation */
}

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(56, 54, 54, 0.7);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 1000;
    display: none;
}

.loading-overlay.visible {
    display: flex;
}

.loading-indicator {
    text-align: center;
    font-size: .9em;
    font-weight: bold;
    color: white;
    background-color: transparent;
    animation: blinker 2s linear infinite;
    margin-bottom: 65px;
}

.response-container {
    flex-grow: 1;
    overflow-y: auto; /* Ensure vertical scrolling */
    display: flex;
    flex-direction: column;
    color: black;
    padding: 5px;
    background-color: #48cdfe;
    box-sizing: border-box;
    height: 100%; /* Adjust to ensure the container height is 100% */
}

.response-container::-webkit-scrollbar {
    width: 12px;
}

.response-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.response-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.response-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}

input[type="text"] {
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.button-container {
    display: flex;
    justify-content: space-between;
}

button[type="submit"], button[title="Clear Chat"], button[title="Copy Chat"] {
    background-color: #48cdfe;
    color: black;
    font-weight: bold;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    margin: 5px;
    box-sizing: border-box; /* Ensure padding is included in the width calculation */
}

.chat {
    margin: 10px 0;
    background-color: #ffffff;
    padding: 10px;
    border-radius: 4px;
}

.user {
    background-color: #ffffff;
    padding: 5px;
    border-radius: 4px;
}

.filebaby {
    background-color: #f2e7f4;
    padding: 5px;
    border-radius: 4px;
}

.filebaby p {
    margin: 0;
    padding: 5px 0;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.chat-title-bar {
    background-color: transparent;
    color: black;
    padding: 10px;
    cursor: move;
    text-align: center;
    border-radius: 8px 8px 0 0;
}

/* Media queries for responsive design */
@media (min-width: 768px) {
    .chat-container.expanded {
        width: 360px; /* Expanded width for desktop */
        max-width: 400px; /* Set max width */
    }
    .loading-indicator {
        font-size: 1.2em;
    }
    .chat-popup {
        width: 360px; /* Ensure popup matches the container width */
    }
}
