/* CheckoutPage.css */
.checkout-page {
    padding: 20px;
    background-color: #fff;
    color: #333;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the content horizontally */
    min-height: 100vh; /* Make sure it takes at least the full viewport height */
}

.checkout-page h2 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center; /* Center the heading text */
}

.checkout-page .cart-list {
    list-style-type: none;
    padding: 0;
    margin-bottom: 20px;
}

.checkout-page .cart-item {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    text-align: center; /* Center the cart item text */
}

.checkout-page h3 {
    font-size: 20px;
    margin-bottom: 20px;
    text-align: center; /* Center the total text */
}

.paypal-buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the PayPal buttons horizontally */
}
