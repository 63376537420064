.media-player {
    padding: 20px;
    background-color: #f9f9f9;
    color: #333;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 20px auto;
}

.media-player h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.media-player .song-description {
    margin-bottom: 20px;
    font-size: 16px;
    color: #666;
}

.media-player .musicimage {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 20px;
}

.thumbnail-navigation {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.thumbnail {
    cursor: pointer;
    width: 50px;
    height: 50px;
    margin: 5px;
    border-radius: 50%;
    border: 2px solid transparent;
    transition: border-color 0.3s ease;
}

.thumbnail.active {
    border-color: #333;
}

.media-player button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.media-player button:hover {
    background-color: #0056b3;
}

.media-player .cart {
    margin-top: 20px;
    text-align: left;
    max-width: 400px;
    margin: 20px auto;
}

.media-player .cart h3 {
    font-size: 20px;
    margin-bottom: 10px;
}

.media-player .cart ul {
    list-style: none;
    padding: 0;
}

.media-player .cart li {
    background-color: #fff;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 5px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
}

.media-player .cart li:last-child {
    border-bottom: none;
}

.media-player .checkout-btn {
    display: block;
    margin: 20px auto;
    background-color: #28a745;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.media-player .checkout-btn:hover {
    background-color: #218838;
}
